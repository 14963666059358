import React from 'react'
import CallToAction from 'src/components/CallToAction'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import Hero from './sections/Hero/_index'
import { PositionStickyDiv } from './style'

const EscritorioDeNegocios = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 col-lg-8'>
            <h3>Encontre o Escritório de Negócios do Banco Inter mais perto de você</h3>
            <h4 className='text-gray--light'>CE</h4>
            <h5 className='fw-400'>Fortaleza</h5>
            <p className='fs-17'>
              Av. Desembargador Moreira,760 - Sala 2001 - Meireles<br />
              CEP: 60170-000
            </p>
            <h4 className='text-gray--light'>DF</h4>
            <h5 className='fw-400'>Brasília</h5>
            <p className='fs-17'>
              SCN QD.1 - Bloco G - Prédio Esplanada, sala 410<br />
              CEP: 70297-400
            </p>
            <h4 className='text-gray--light'>ES</h4>
            <h5 className='fw-400'>Vitória</h5>
            <p className='fs-17'>
              Rua Prof. Almeida Cousin, 125/903 - Enseada do Suá<br />
              CEP: 29050-565
            </p>
            <h4 className='text-gray--light'>GO</h4>
            <h5 className='fw-400'>Goiânia</h5>
            <p className='fs-17'>
              Rua 3, 1022 - Sala 707 - Ed. West Office - Setor Oeste<br />
              CEP: 74115-050
            </p>
            <h4 className='text-gray--light'>MG</h4>
            <h5 className='fw-400'>Belo Horizonte</h5>
            <p className='fs-17'>
              Escritório – Rua Conde de Linhares, 77 – Cidade Jardim<br />
              CEP: 30380-030
            </p>
            <p className='fs-17'>
              Matriz do Banco Inter - Av. Barbacena, 1.219 - Santo Agostinho<br />
              CEP: 30190-131
            </p>
            <h4 className='text-gray--light'>PR</h4>
            <h5 className='fw-400'>Curitiba - Filial</h5>
            <p className='fs-17'>
              Rua Comendador Araújo, 565 - Sala 704 - Ed. Augusto Prolik - Centro<br />
              CEP: 80420-908
            </p>
            <h4 className='text-gray--light'>SC</h4>
            <h5 className='fw-400'>Balneário Camboriú</h5>
            <p className='fs-17'>
              Av. do Estado Dalmo Vieira, 2425 - Sala 504 - Ed. Pacifico Empresarial – Centro<br />
              CEP: 88330-075
            </p>
            <h4 className='text-gray--light'>SP</h4>
            <h5 className='fw-400'>São Paulo - Filial</h5>
            <p className='fs-17'>
              Av. Presidente Juscelino Kubitschek, 1400 – 8º andar<br />
              Cond. Maria Cecília Lara Campos - Jardim Paulista<br />
              CEP: 04543-000
            </p>
            <h5 className='fw-400'>Campinas</h5>
            <p className='fs-17'>
              Avenida José de Souza Campos, 1073 - Sala 101 e 103 - Cambuí,<br />
              CEP: 13092-123
            </p>
          </div>
          <div className='col-12 col-lg-4'>
            <PositionStickyDiv>
              <CallToAction
                section='dobra_2'
                elementAction='click button'
                elementName='Abra sua conta'
                sectionName='Encontre o Escritório de Negócios do Banco Inter mais perto de você'
                redirectUrl='/pra-voce/conta-digital/pessoa-fisica/'
                text='Abra sua conta'
                link='/pra-voce/conta-digital/pessoa-fisica/'
              >
                <h3 className='fs-17 mb-2'>e fique livre de tarifas.</h3>
              </CallToAction>
            </PositionStickyDiv>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EscritorioDeNegocios
