import React from 'react'
import Highlight from 'src/components/Layout/NewHighlight'
import usePageQuery from '../../pageQuery'
import pageContext from '../../pageContext.json'

const Hero = () => {
  const data = usePageQuery()

  return (
    <Highlight highlightContext={pageContext} bannerDesktop={data.bannerDesktop.fluid} bannerMobile={data.bannerMobile.fluid} />
  )
}

export default Hero
